import { useEffect, useState } from "react"
import { Location, useLocation } from "react-router-dom"
import { useStore } from "../store/root"

type Paq = (string | ((this: any) => void))[]
declare namespace window {
  const _paq: Paq[]
}

const matomoCmd = (...args: Paq) => {
  if (window._paq) {
    window._paq.push(args);
  }
}

export const Matomo = () => {
  const [previousLocation, setPreviousLocation] = useState<Location | null>(null)
  const isAuthenticated = useStore(s => s.isAuthenticated)
  const hasVerifiedToken = useStore(s => s.hasVerifiedToken)
  const currentUser = useStore(s => s.currentUser)
  const location = useLocation()

  const [wasAuthenticated, setWasAuthenticated] = useState(false)

  const setCurrentUserEffect = () => {
    if (isAuthenticated && hasVerifiedToken) {
      matomoCmd('setUserId', currentUser!.name ?? currentUser!.email)
      matomoCmd('trackPageView')
    } else if (wasAuthenticated) {
      matomoCmd('resetUserId')
      matomoCmd('trackPageView')
    }

    return () => {
      setWasAuthenticated(isAuthenticated && hasVerifiedToken)
    }
  }

  const routerChangeEffect = () => {
    // We use setTimeout to record the page view after other page logic has
    // potentially to modified the page.
    setTimeout(() => {
      if (previousLocation) {
        matomoCmd('setReferrerUrl', previousLocation.pathname)
      }

      matomoCmd('setCustomUrl', location.pathname)
      matomoCmd('setDocumentTitle', document.title)
      matomoCmd('trackPageView')
    }, 0)

    return () => { setPreviousLocation(location) }
  }

  useEffect(setCurrentUserEffect, [isAuthenticated, hasVerifiedToken])
  useEffect(routerChangeEffect, [location.pathname])

  return null
}
