import { Slice } from "./root"

export type UiState = {
  showMobileNav: boolean

  setShowMobileNav(show: boolean): void
}

export const createUiState: Slice<UiState> = (set) => ({
  showMobileNav: false,

  setShowMobileNav(show) {
    set(s => { s.showMobileNav = show })
  }
})
