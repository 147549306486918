import ky, { AfterResponseHook } from 'ky'

export type ServerErrorPathIssue = {
  type: string,
  message?: string
}

export type ServerError = {
  type: string,
  message?: string,
  pathIssues?: Record<string, ServerErrorPathIssue[]>
}

export type UnauthorizedHandler = () => void

export const API_URL = process.env.API_URL ?? 'http://localhost:9169'
export const UNAUTHORIZED_CHECK_EXCLUDED_PATHS: { method: string, path: string }[] = [
  { method: 'post', path: '/token' },
  { method: 'post', path: '/user/reset-password' },
]

const onUnauthorizedHandlers: UnauthorizedHandler[] = []

export const onUnauthorized = (handler: UnauthorizedHandler) => {
  onUnauthorizedHandlers.push(handler)
}

export const offUnauthorized = (handler: UnauthorizedHandler) => {
  const index = onUnauthorizedHandlers.indexOf(handler)
  if (index === -1) { return }
  onUnauthorizedHandlers.splice(index, 1)
}

const handleUnauthorized: AfterResponseHook = (request, _options, response) => {
  const isExludedPath = UNAUTHORIZED_CHECK_EXCLUDED_PATHS.some(({ method, path }) =>
    request.method.toLowerCase() === method.toLowerCase() ||
    new URL(request.url).pathname.toLowerCase() === path.toLowerCase(),
  )

  if (response.status !== 401 || isExludedPath) {
    return
  }

  onUnauthorizedHandlers.forEach(h => h())
}

export let apiClient = ky.create({
  prefixUrl: API_URL,
  hooks: {
    afterResponse: [
      handleUnauthorized,
    ],
  },
})

export const setToken = (token: string) => {
  apiClient = apiClient.extend({
    headers: {
      Authorization: token,
    },
  })
}

export const unsetToken = () => {
  apiClient = apiClient.extend({
    headers: {
      Authorization: undefined,
    },
  })
}
