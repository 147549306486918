import create, { StateCreator } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { AssistanceRequestState, createAssistanceRequestsState } from './assistance-requests'
import { createPhotosState, PhotosState } from './photos'
import { createTokenState, TokenState } from './token'
import { createUiState, UiState } from './ui'
import { createUsersState, UsersState } from './users'

export type RootState =
  & AssistanceRequestState
  & PhotosState
  & TokenState
  & UiState
  & UsersState

export type Slice<State> = StateCreator<RootState, Middleware, [], State>

export type Middleware = [
  ["zustand/devtools", never],
  ["zustand/immer", never],
]

export const useStore = create<RootState>()(devtools(immer((...a) => ({
  ...createAssistanceRequestsState(...a),
  ...createPhotosState(...a),
  ...createTokenState(...a),
  ...createUiState(...a),
  ...createUsersState(...a)
}))))
