import { apiClient, ServerError, setToken, unsetToken } from "../api-client"
import { Slice } from "./root"
import { User } from "./users"

export type Token = {
  id: string
  user: User
  token?: string
  expiresAt: Date
}

export type TokenState = {
  token: {
    value: string
    expiresAt: Date | null
  }
  currentUser: User | null

  isAuthenticated: boolean
  hasVerifiedToken: boolean
  loginError: ServerError | null

  setToken(token: Token): void
  clearToken(): void

  login(invitationCode: string): Promise<void>
  verifyLogin(): Promise<void>
}

const existingTokenValue = localStorage.getItem('token') ?? ''
if (existingTokenValue) { setToken(existingTokenValue) }

export const createTokenState: Slice<TokenState> = (set, get) => ({
  token: {
    value: existingTokenValue,
    expiresAt: null,
  },
  currentUser: null,
  isAuthenticated: !!existingTokenValue,
  hasVerifiedToken: false,
  loginError: null,

  setToken(token) {
    set(s => {
      s.token.expiresAt = token.expiresAt
      s.currentUser = token.user
      s.isAuthenticated = true
      s.hasVerifiedToken = true
      if (token.token) {
        localStorage.setItem('token', token.token)
        setToken(token.token)
        s.token.value = token.token
      }
    })
  },

  clearToken() {
    set(s => {
      s.token.value = ''
      s.token.expiresAt = null
      s.currentUser = null
      s.isAuthenticated = false
      s.hasVerifiedToken = false
      localStorage.removeItem('token')
      unsetToken()
    })
  },

  async login(invitationCode) {
    const tokenRes = await apiClient.post('token', {
      json: { invitationCode },
      throwHttpErrors: false,
    })

    let tokenBody: any = {}
    try {
      // eslint-disable-next-line prefer-const
      tokenBody = await tokenRes.json()
    } catch {}

    if (tokenRes.status === 401) {
      set(s => {
        s.loginError = {
          type: 'unauthorized',
          pathIssues: {
            invitationCode: [{
              type: 'invalid'
            }]
          }
        }
      })
      return
    }

    if (tokenRes.status !== 201) {
      set(s => { s.loginError = { type: 'invalid' } })
      return
    }

    get().setToken(tokenBody)
  },

  async verifyLogin() {
    const tokenRes = await apiClient.get('token', { throwHttpErrors: false })

    let tokenBody: any = {}
    try {
      // eslint-disable-next-line prefer-const
      tokenBody = await tokenRes.json()
    } catch {}

    if (tokenRes.status !== 200) {
      get().clearToken()
    }

    get().setToken(tokenBody)
  },
})
