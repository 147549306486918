import React, { Suspense } from 'react'
import { Route, Routes, BrowserRouter as Router, Navigate } from 'react-router-dom'

import { GlobalStyle } from './styles/global'

import { useFetchAuthentication } from './hooks/use-fetch-authentication'

import { ResetScroll } from './components/ResetScroll'
import { Redirect } from './components/Redirect'
import { Matomo } from './components/Matomo'

const HomeView = React.lazy(() => import('./views/Home'))
const EventInformation = React.lazy(() => import('./views/EventInformation'))
const AdminGuestListView = React.lazy(() => import('./views/AdminGuestList'))
const LogoutView = React.lazy(() => import('./views/Logout'))
const EventGalleryView = React.lazy(() => import('./views/EventGallery'))
const EventRegistryView = React.lazy(() => import('./views/EventRegistry'))
const AdminGuestView = React.lazy(() => import('./views/AdminGuest'))

const CheckAuthentication = () => {
  useFetchAuthentication()
  return null
}

export const App = () => <>
  <CheckAuthentication />
  <GlobalStyle/>
  <Router>
    <ResetScroll/>
    <Matomo />
    <Suspense fallback="">
      <Routes>
        <Route path='/event' element={<Redirect to="/event/gallery"/>}/>
        <Route path='/event/information' element={<EventInformation/>}/>
        <Route path='/event/gallery' element={<EventGalleryView/>}/>
        <Route path='/event/registry' element={<EventRegistryView/>}/>
        
        <Route path='/admin' element={<Navigate replace to='/admin/guest'/>}/>
        <Route path='/admin/guest' element={<AdminGuestListView/>}/>
        <Route path='/admin/guest/:id' element={<AdminGuestView/>}/>

        <Route path='/logout' element={<LogoutView/>}/>
        <Route path='/magic-link' element={<HomeView/>}/>
        <Route path='/' element={<HomeView/>}/>
      </Routes>
    </Suspense>
  </Router>
  <div id="modal" />
  <div id="overlays" />
</>
