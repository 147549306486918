import { apiClient } from "../api-client"
import { Slice } from "./root"

export type Photo = {
  id: string
  caption: string
  userId: string
  isApproved: boolean
}

export type PhotosState = {
  photos: Photo[]
  engagementPhotos: Photo[]
  weddingPhotos: Photo[]
  userPhotos: Photo[]

  getPhotos(): Promise<void>
  uploadPhoto(formData: FormData): Promise<void>
  updatePhoto(id: string, photo: Partial<Photo>): Promise<void>
  deletePhoto(id: string): Promise<void>
}

export const createPhotosState: Slice<PhotosState> = (set, get) => ({
  photos: [],
  engagementPhotos: [],
  weddingPhotos: [],
  userPhotos: [],

  async getPhotos () {
    const photosRes = await apiClient.get('photo', {
      throwHttpErrors: false,
    })

    let photosBody: any = {}
    try {
      // eslint-disable-next-line prefer-const
      photosBody = await photosRes.json()
    } catch {}

    if (photosRes.status !== 200) {
      return
    }

    for (var i = photosBody.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = photosBody[i];
      photosBody[i] = photosBody[j];
      photosBody[j] = temp;
    }

    const engagementPhotos = photosBody.filter((p: any) => p.category === 'engagement')
    const weddingPhotos = photosBody.filter((p: any) => p.category === 'wedding')
    const userPhotos = photosBody.filter((p: any) => p.category === 'user')

    set(s => {
      s.photos = photosBody
      s.engagementPhotos = engagementPhotos
      s.weddingPhotos = weddingPhotos
      s.userPhotos = userPhotos
    })
  },

  async uploadPhoto (photoData) {
    const photosRes = await apiClient.post(`photo`, {
      body: photoData,
      throwHttpErrors: false,
    })

    if (photosRes.status !== 201) {
      return
    }

    await get().getPhotos()
  },

  async updatePhoto (id, photo) {
    const photosRes = await apiClient.put(`photo/${id}`, {
      json: photo,
      throwHttpErrors: false,
    })

    if (photosRes.status !== 204) {
      return
    }

    set(s => {
      const targetPhoto = s.photos.find(p => p.id === id)
      if (targetPhoto) {
        Object.assign(targetPhoto, photo)
        s.engagementPhotos = s.photos.filter((p: any) => p.category === 'engagement')
        s.weddingPhotos = s.photos.filter((p: any) => p.category === 'wedding')
        s.userPhotos = s.photos.filter((p: any) => p.category === 'user')
      }
    })
  },

  async deletePhoto (id) {
    const photosRes = await apiClient.delete(`photo/${id}`, {
      throwHttpErrors: false,
    })

    if (photosRes.status !== 204) {
      return
    }

    set(s => {
      const targetPhotoIndex = s.photos.findIndex(p => p.id === id)
      if (targetPhotoIndex !== -1) { s.photos.splice(targetPhotoIndex, 1) }
      s.engagementPhotos = s.photos.filter((p: any) => p.category === 'engagement')
      s.weddingPhotos = s.photos.filter((p: any) => p.category === 'wedding')
      s.userPhotos = s.photos.filter((p: any) => p.category === 'user')
    })
  }
})
