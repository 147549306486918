import './fonts.css'

import { css } from '@emotion/styled'

export const fontFamilies = {
  display: 'Blacksword, cursive',
  body: 'Crimson Text, serif',
}

export type FontFamily = keyof typeof fontFamilies

export const getFontFamily = (color: FontFamily) => fontFamilies[color]

const fontStyles = {
}

export type FontStyle = keyof typeof fontStyles

export const getFontStyle = (name: FontStyle) => {
  if (!fontStyles[name]) {
    console.warn(`Unknown font style '${name}'.`)
    return ''
  }
  return fontStyles[name]
}
