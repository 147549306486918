export const colors = {
  primaryGreen: '#1B4736',
  secondaryGreen: '#365E55',
  stone: '#F7F7F2',
  secondaryGold: '#DBD69E',
  primaryGold: '#A39140',

  formError: '#B81E28',
  formBorderDisabled: '#CFCFCF',
  formBackgroundDisabled: '#EBEBEB',





















  gold900: '#fbf9f3',
  gold800: '#f5f1e4',
  gold700: '#f0ead6',
  gold600: '#ebe3c8',
  gold500: '#e5dbb9',
  gold400: '#e0d4ab',
  gold300: '#dbcd9d',
  gold200: '#d6c58e',
  gold100: '#d0be80',
  
  green100: '#404c1c',


  // Temp Colors Below
  
  // Named Purple colors
  purpleSuperLight: '#F6F5FF',
  purpleLight: '#A593FF',
  purpleRegular: '#8070F1',
  purpleDark: '#6B5FC6',
  purpleExtremeDark: '#3B3566',

  // Purple Colors
  purple10: '#F6F5FF',
  purple20: '#D9D4FF',
  purple30: '#BDB5FC',
  purple40: '#A593FF',
  purple50: '#8070F1',
  purple60: '#6B5FC6',
  purple90: '#3B3566',

  // Blue colors
  blue10: '#EDF4FF',
  blue20: '#CFE1FF',
  blue30: '#A8C8FF',
  blue40: '#87B2FF',
  blue50: '#6B9DFF',
  blue60: '#5789FF',
  blue80: '#386DEB',

  // Green colors
  green10: '#F0FAF7',
  green20: '#CAEBE0',
  green30: '#9ADEC8',
  green40: '#6FD1B3',
  green50: '#3CCBA3',
  green60: '#25B890',
  green80: '#008762',

  // Grey colors
  grey10: '#F7F8FA',
  grey20: '#E6EBF2',
  grey30: '#D3DAE3',
  grey40: '#B8C3D1',
  grey50: '#9FAEC2',
  grey60: '#8092AB',
  grey70: '#60728B',
  grey80: '#4E5D72',
  grey90: '#37414F',

  // Red colors
  red10: '#FFF3F3',
  red20: '#FFCFCF',
  red30: '#FDA5A7',
  red40: '#FF6E71',
  red50: '#F24D53',
  red60: '#E03A45',
  red80: '#B81E28',

  // Yellow colors
  yellow10: '#FFFBF0',
  yellow20: '#FFEDBA',
  yellow30: '#FFD970',
  yellow40: '#FFC72E',
  yellow50: '#FFAD24',
  yellow60: '#FF981A',
  yellow80: '#B06A15',

  // Lime colors
  lime10: '#F9FAE6',
  lime20: '#EEF0B3',
  lime30: '#E3E87D',
  lime40: '#D4DB42',
  lime50: '#C1CF29',
  lime60: '#A9BD26',
  lime80: '#72830C',

  // Additional colors
  syberia: '#6DD3FA',
  mint: '#8AE592',
  magenta: '#E64C68',
  orange: '#F27F44',
  pink: '#FA7DBE',
  lavender: '#D180FF',
  lemon: '#F7DC2D',
  grass: '#2B993E',
  navy: '#174BC2',
  mauve: '#833A85',
  latte: '#A7837F',
  chocolate: '#705C5A',
  sangria: '#C43B8D',
  ocean: '#00676D',
  bronze: '#857300',
  turquoise: '#56DCD1',

  // white and black
  white: '#FFFFFF',
  black: '#1D1C29'
}

export type Color = keyof typeof colors

export const getColor = (color: Color, opacity?: number) => {
  if (opacity !== undefined) {
    const opacityHex = Math.round(Math.min(Math.max(opacity, 0), 1) * 255)
      .toString(16)
      .padStart(2, '0')
    return `${colors[color]}${opacityHex}`
  }
  return colors[color]
}