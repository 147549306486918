import { apiClient, ServerError } from "../api-client"
import { Slice } from "./root"

export type User = {
  id: string
  name: string
  email?: string
  phone?: string
  isAttending: 'unknown' | 'yes' | 'no'
  messageToBrideAndGroom: string
  isAdmin: boolean
  invitationCode: string
}

type NewUser = Omit<User, 'id' | 'isAdmin' | 'isAttending' | 'messageToBrideAndGroom'>
type UserUpdate = Partial<Omit<User, 'id' | 'isAdmin'>>

export type UsersState = {
  user: User | null
  users: User[]
  createUserError: ServerError | null

  getUsers(): Promise<void>
  createUser(newUser: NewUser): Promise<void>
  getUser(id: string): Promise<void>
  updateUser(id: string, userUpdate: UserUpdate): Promise<void>
  deleteUser(id: string): Promise<void>
}

export const createUsersState: Slice<UsersState> = (set) => ({
  user: null,
  users: [],
  createUserError: null,

  async getUsers() {
    const usersRes = await apiClient.get('user', { throwHttpErrors: false })

    let usersBody: any = {}
    try {
      // eslint-disable-next-line prefer-const
      usersBody = await usersRes.json()
    } catch {}

    if (usersRes.status !== 200) {
      return
    }

    set(s => { s.users = usersBody })
  },

  async createUser(newUser) {
    const payload = { ...newUser }
    if (!payload.email) { delete payload.email }
    if (!payload.phone) { delete payload.phone }

    const userRes = await apiClient.post('user', {
      json: payload,
      throwHttpErrors: false
    })

    let userBody: any = {}
    try {
      // eslint-disable-next-line prefer-const
      userBody = await userRes.json()
    } catch {}

    if (userRes.status !== 201) {
      return
    }

    set(s => {
      s.user = userBody
      s.users.push(userBody)
    })
  },

  async getUser(id) {
    const userRes = await apiClient.get(`user/${id}`, {
      throwHttpErrors: false,
    })

    let userBody: any = {}
    try {
      // eslint-disable-next-line prefer-const
      userBody = await userRes.json()
    } catch {}

    if (userRes.status !== 200) {
      return
    }

    set(s => { s.user = userBody })
  },

  async updateUser(id, userUpdate) {
    const payload = { ...userUpdate }
    if (!payload.email) { delete payload.email }
    if (!payload.phone) { delete payload.phone }

    const userRes = await apiClient.put(`user/${id}`, {
      json: payload,
      throwHttpErrors: false
    })

    if (userRes.status !== 204) {
      return
    }

    set(s => {
      s.users = s.users.filter(u => u.id !== id)
    })
  },

  async deleteUser(id) {
    const userRes = await apiClient.delete(`user/${id}`, {
      throwHttpErrors: false
    })

    if (userRes.status !== 204) {
      return
    }

    set(s => {
      s.users = s.users.filter(u => u.id !== id)
    })
  }
})
 