import { useEffect } from 'react'
import { useStore } from '../store/root'

export const useFetchAuthentication = () => {
  const verifyLogin = useStore(s => s.verifyLogin)
  const hasVerifiedToken = useStore(s => s.hasVerifiedToken)
  const isAuthenticated = useStore(s => s.isAuthenticated)

  useEffect(() => {
    if (!isAuthenticated || location.pathname === '/logout') { return }
    if (!hasVerifiedToken) {
      verifyLogin()
      return
    }
  }, [hasVerifiedToken, isAuthenticated])
}
