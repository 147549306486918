import React from 'react'
import { css, Global } from '@emotion/react';
import { getColor } from "./colors";
import { getFontFamily } from "./fonts";
import { getRuleForSize } from "./media-queries";

const globalStyle = css`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
    height: 100%;
  }

  body {
    display: flex;
    flex-direction: column;
    margin: 0;
    font-size: 1.6em;
    min-height: 100%;
    min-width: 0;
    font-family: ${getFontFamily('body')};
    background: ${getColor('stone')};
    color: ${getColor('primaryGreen')};
  }

  #app {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  #modal,
  #overlays {
    z-index: 100;
  }

  p {
    margin: 0 0 1.6rem 0;
    font-size: 1.8rem;
    line-height: 2.2rem;

    @media ${getRuleForSize('tablet')} {
      margin: 0 0 3.2rem 0;
    }
  }

  a {
    color: ${getColor('primaryGold')};

    &:hover {
      color: ${getColor('gold200')};
    }
  }
`

export const GlobalStyle = () => <Global styles={globalStyle} />
