import { apiClient } from "../api-client"
import { Slice } from "./root"

export type AssistanceRequest = {
  id: string
  name: string
  createdAt: string
}

export type AssistanceRequestState = {
  assistanceRequests: AssistanceRequest[]

  getAssistanceRequests(): void
  createAssistanceRequest(name: string): Promise<void>
  deleteAssistanceRequest(id: string): Promise<void>
}

export const createAssistanceRequestsState: Slice<AssistanceRequestState> = (set) => ({
  assistanceRequests: [],

  async getAssistanceRequests() {
    const assistanceRequestsRes = await apiClient.get('assistance-request', { throwHttpErrors: false })

    let assistanceRequestsBody: any = {}
    try {
      // eslint-disable-next-line prefer-const
      assistanceRequestsBody = await assistanceRequestsRes.json()
    } catch {}

    if (assistanceRequestsRes.status !== 200) {
      return
    }

    set(s => { s.assistanceRequests = assistanceRequestsBody })
  },

  async createAssistanceRequest(name) {
    const assistanceRequestRes = await apiClient.post('assistance-request', {
      json: { name },
      throwHttpErrors: false
    })

    let assistanceRequestBody: any = {}
    try {
      // eslint-disable-next-line prefer-const
      assistanceRequestBody = await assistanceRequestRes.json()
    } catch {}

    if (assistanceRequestRes.status !== 201) {
      return
    }

    set(s => {
      s.assistanceRequests.push(assistanceRequestBody)
    })
  },

  async deleteAssistanceRequest(id) {
    const userRes = await apiClient.delete(`assistance-request/${id}`, {
      throwHttpErrors: false
    })

    if (userRes.status !== 204) {
      return
    }

    set(s => {
      s.assistanceRequests = s.assistanceRequests.filter(a => a.id !== id)
    })
  }
})
 