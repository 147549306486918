import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export type RedirectProps = {
  to: string
}

export const Redirect = (props: RedirectProps) => {
  const { to } = props
  const navigate = useNavigate()

  const redirectEffect = () => {
    navigate(to)
  }
  useEffect(redirectEffect, [])

  return null
}
