/**
 * MediaQuery is a string containing a media query or an object describing a
 * media query. Note that some query options are not avalible as they are not
 * supported by current browsers. For help understanding the avalible query
 * options see the follwing link:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries/Using_media_queries
 */
 export type MediaQuery =
  | string
  | {
    not?: boolean
    all?: boolean
    print?: boolean
    screen?: boolean
    speech?: boolean
    anyHover?: 'none' | 'hover'
    anyPointer?: 'none' | 'coarse' | 'fine'
    aspectRatio?: string
    color?: number
    minColor?: number
    maxColor?: number
    colorGamut?: 'srgb' | 'p3' | 'rec2020'
    colorIndex?: number
    minColorIndex?: number
    maxColorIndex?: number
    displayMode?: 'fullscreen' | 'standalone' | 'minimal-ui' | 'browser'
    forcedColors?: 'none' | 'active'
    grid?: boolean
    height?: number
    minHeight?: number
    maxHeight?: number
    hover?: 'none' | 'hover'
    invertedColors?: 'none' | 'inverted'
    lightLevel?: 'dim' | 'normal' | 'washed'
    monochrome?: number
    minMonochrome?: number
    maxMonochrome?: number
    orientation?: 'portrait' | 'landscape'
    overflowBlock?: 'none' | 'scroll' | 'optional-paged' | 'paged'
    overflowInline?: 'none' | 'scroll'
    pointer?: 'none' | 'coarse' | 'fine'
    prefersColorScheme?: 'no-preference' | 'light' | 'dark'
    prefersContrast?: 'no-preference' | 'high' | 'low'
    prefersReducedTransparency?: 'no-preference' | 'reduce'
    resolution?: number
    minResolution?: number
    maxResolution?: number
    scripting?: 'none' | 'initial-only' | 'enabled'
    width?: number
    minWidth?: number
    maxWidth?: number
  }

  export const mediaQuerySizes = {
    smallPhone  : { maxWidth: 375  } as MediaQuery,
    // Note: phone is skipped as that is our default target. It is 376px and up in width.
    tablet      : { minWidth: 768  } as MediaQuery,
    laptop      : { minWidth: 1280 } as MediaQuery,
    desktop     : { minWidth: 1440 } as MediaQuery,
    largeDesktop: { minWidth: 2560 } as MediaQuery
  }
  
  export type MediaQuerySize = keyof typeof mediaQuerySizes

  export const getRule = (query: MediaQuery) => typeof query === 'string'
    ? `${query}`
    : Object.entries(query)
      .map(([key, value]) => {
        const name = key.replace(/(.)([A-Z])/, (m, g1, g2) =>
          g1 ? `${g1}-${g2.toLowerCase()}` : `${g2.toLowerCase()}`
        )

        switch (key) {
          case 'not':
          case 'all':
          case 'print':
          case 'screen':
          case 'speech':
            return key

          case 'grid':
            return `(${name}: ${value ? '1' : '0'})`

          case 'width':
          case 'minWidth':
          case 'maxWidth':
          case 'height':
          case 'minHeight':
          case 'maxHeight':
            return `(${name}: ${value}px)`

          default:
            return `(${name}: ${String(value)})`
        }
      })
      .join(' and ')

export const getRuleForSize = (size: MediaQuerySize) => {
  return getRule(mediaQuerySizes[size])
}
